import { FC, memo, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import { Card, CardContent } from '@mui/material'
import {
    AnimationOptions,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    EasingFunction,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels'
import InfoPopover from '../../screens/BudgetScreen/PopupInfoCharts'
import {useIsDesktop, useIsMobile, useScreenBrakpoints} from '../../../utils/hooks';
import { useIntl } from 'react-intl'
import { BudgetMessages } from '../../../lang/Messages'
import BackendServices from '../../../services/BackendServices'
import { result } from 'lodash'

ChartJS.register(CategoryScale, ChartDataLabels, LinearScale, BarElement, Title, Tooltip, Legend)

const animationActiveOption: AnimationOptions<'bar'>['animation'] = {
    duration: 300,
    loop: false,
    easing: 'easeOutQuart' as EasingFunction,
}

const RoiBarChart: FC<Props> = (props) => {
    const { graphData, title, vertical } = props

    // Obtiene el currency almacenado en el local storage
    const currency = localStorage.getItem('currency') || '$';

    const graphWithStyles: ChartData<'bar', number[], string> = {
        ...graphData,
        datasets: graphData.datasets.map((dataset, index) => ({
            ...dataset,
            borderRadius: 5,
            animation: index === 0 ? animationActiveOption : false,
        })),
    }
    const {isUpXl: isBigScreen } = useScreenBrakpoints()

    const commonOptions: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: isBigScreen ? 2.2 : 1.5,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                anchor: 'end',
                display: true,
                color: (ctx: Context) => {
                    const value = ctx.dataset.data[ctx.dataIndex]
                    const maxValue = Math.max(...(ctx.dataset.data as number[]))
                    return value && (value as number) / maxValue > 0.5
                        ? ('white' as const)
                        : (ctx.dataset.backgroundColor?.[ctx.dataIndex as keyof typeof ctx.dataset.backgroundColor] as unknown as string) || 'white'
                },
                formatter: (value: number, context: { dataset: { label?: string } }) => {
                    const {
                        dataset: { label },
                      } = context;
                      if (value === 0) {
                        return '';
                      }
                      return label === 'type1' ? currency + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
                },
            },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hover: false,
    }

    const occupancyOptions: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: isBigScreen ? 2.2 : 1.5,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: '',
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                anchor: 'end',
                display: true,
                color: (ctx: Context) => {
                    const value = ctx.dataset.data[ctx.dataIndex]
                    const maxValue = Math.max(...(ctx.dataset.data as number[]))
                    return value && (value as number) / maxValue > 0.5
                        ? ('white' as const)
                        : (ctx.dataset.backgroundColor?.[ctx.dataIndex as keyof typeof ctx.dataset.backgroundColor] as unknown as string) || 'white'
                },
                formatter: (value: number, context: { dataset: { label?: string } }) => {
                    const {
                        dataset: { label },
                      } = context;
                      if (value === 0) {
                        return '';
                      }
                      return label === 'type1' ? Math.min(value, 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%' : '';
                },
            },
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hover: false,
    }

    const verticalOptions: ChartOptions<'bar'> = {
        ...occupancyOptions,
        indexAxis: 'x' as const,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        barThickness: isBigScreen ? 50 :'flex',
        plugins: {
            ...occupancyOptions.plugins,
            datalabels: {
                ...occupancyOptions.plugins?.datalabels,
                align: (ctx: Context) => {
                    const value = ctx.dataset.data[ctx.dataIndex]
                    const maxValue = Math.max(...(ctx.dataset.data as number[]))
                    return value && (value as number) / maxValue > 0.5 ? ('bottom' as const) : ('top' as const)
                },
            },
        },

        scales: {
            y: {
                display: false,
                grid: {
                    display: false,
                },
                ticks: {
                    callback: () => '',
                },
                stacked: true,
                min: 0,
            },
            x: {
                stacked: true,
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
        },
    }

    const horizontalOptions: ChartOptions<'bar'> = {
        ...commonOptions,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        barThickness: isBigScreen ? 25 :'flex',
        indexAxis: 'y' as const,
        // animation:false,

        plugins: {
            ...commonOptions.plugins,
            datalabels: {
                ...commonOptions.plugins?.datalabels,
                align: (ctx: Context) => {
                    const value = ctx.dataset.data[ctx.dataIndex]
                    const maxValue = Math.max(...(ctx.dataset.data as number[]))
                    return value && (value as number) / maxValue > 0.5 ? ('left' as const) : ('right' as const)
                },
            },
        },

        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: 1,
                    callback: () => '',
                },
                stacked: true,
                min: 0,
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
        },
    }

    const { formatMessage } = useIntl()

    return (
        <div className="card">
            <Card>
                <CardContent className="card-content">
                    <div className="title-and-info-popup">
                        <Typography variant="h5">{title}</Typography>
                        <InfoPopover
                            title={title}
                            textPopover={
                                title === 'Revenue'
                                    ? `${formatMessage(BudgetMessages.budgetRevenueDescription)}`
                                    : title === 'Ingresos'
                                    ? `${formatMessage(BudgetMessages.budgetRevenueDescription)}`
                                    : title === 'RevPAR'
                                    ? `${formatMessage(BudgetMessages.budgeteRevParDescription)}`
                                    : title === 'ADR'
                                    ? `${formatMessage(BudgetMessages.budgetADRDescription)}`
                                    : `${formatMessage(BudgetMessages.BudgetOccupancyDescription)}`
                            }
                        />
                    </div>

                    <Bar
                        options={vertical ? verticalOptions : horizontalOptions}
                        data={graphWithStyles}
                        updateMode="normal"
                        plugins={[ChartDataLabels]}
                    />
                </CardContent>
            </Card>
        </div>
    )
}

type Props = {
    graphData: {
        labels: string[]
        datasets: {
            label: string
            data: number[]
            borderColor: string[]
            backgroundColor: string[]
        }[]
    }
    title: string
    vertical?: boolean
}

export default memo(RoiBarChart)
